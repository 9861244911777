<template>
  <div class="schemes">
    <div class="schemes-inner">
      <img v-if="isVisaAvailable" slot="image" src="@/assets/images/schemes/nets-storebox.png" width="50" height="30" alt="Icon">
      <img v-if="isMastercardAvailable" slot="image" src="@/assets/images/schemes/mc-cls.png" width="50" height="30" alt="Icon">
      <img v-if="isVisaAvailable" slot="image" src="@/assets/images/schemes/visa-vop.png" width="50" height="30" alt="Icon">
    </div>
  </div>
</template>

<script>
  import CardSchemesConfig from '@/configs/cardSchemesConfig';

  export default {
    name: "Schemes",

    data: function () {
      return {
        isVisaAvailable: false,
        isMastercardAvailable: false,
      }
    },

    mounted: function () {
      this.$nextTick(function () {
        this.isVisaAvailable       = CardSchemesConfig.VISA_IS_AVAILABLE;
        this.isMastercardAvailable = CardSchemesConfig.MASTERCARD_IS_AVAILABLE;
      });
    },
  }
</script>

<style lang="scss" scoped>
  .schemes {
    width: 100%;

    .schemes-inner {
      width: 100%;
      display: flex;
      justify-content: space-between;

      img {
        width: 50px;
        height: 30px;
        display: block;
      }
    }
  }
</style>
