export default Object.freeze({
    NORDEA: [
        453900, 541253, 543288, 543321, 552048, 975221, 439528, 435929, 435930, 492010, 492015, 492017, 492020, 492021,
        498501, 512608, 512746, 514558, 516001, 516382, 518706, 520680, 520991, 521319, 521725, 521823, 522054, 522275,
        522575, 522580, 522841, 523004, 524342, 526948, 529492, 531717, 539202, 539670, 541253, 541277, 542908, 543065,
        543066, 543073, 543288, 543321, 545139, 545185, 545190, 547643, 552048, 552153, 552513, 552514, 552518, 552519,
        554974, 554977, 557450, 557451, 557496, 557892, 558791
    ],

    SEB: [
        513149, 515675, 522229, 525306, 525412, 525713, 527501, 527502, 530077, 534489, 535583, 536435, 540022, 540185,
        540287, 541249, 541255, 541256, 541303, 544481, 545114, 545857, 547131, 550028, 554489, 555014, 557053, 557082,
        589973
    ]
});
