import PermittedBinsConfig from '@/configs/permittedBinsConfig';

export default class PermittedBinNumbers {
    constructor(cardNumber) {
        this.cardNumber  = cardNumber;
        this.lopiPermittedBins = PermittedBinsConfig.PERMITTED_LOPI_BINS;
    }
    
    isPermittedLopiBins() {
        let cardNumber = this.cardNumber;

        if (cardNumber && cardNumber.length >= 6) {
            let firstSixBins = parseInt(cardNumber.substring(0, 6));
            return this.lopiPermittedBins.includes(firstSixBins);
        }

        return false;
    }
}
