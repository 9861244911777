import CreditCardsDefault from '@/models/creditCardsDefault';

export default class VisaCardNumber extends CreditCardsDefault{
    constructor(cardNumber) {
        super(cardNumber);
    }

    isScheme() {
        // Checks if card number starts with 4
        return this.cardNumber.match(/^4\d*$/);
    }
}
