<template>
  <modal-default refs="modal-mastercard-scheme" :buttonsSlot="false">
    <template v-slot:content>
      <h3>
        VILKÅR OG BETINGELSER FOR CARD LINKING
      </h3>

      <p>
        This is a placeholder for the Mastercard Terms and Conditions
      </p>
    </template>
  </modal-default>
</template>

<script>
  import ModalDefault from '@/components/ui/modal/components/Default';

  export default {
    name: "ModalMastercardScheme",

    components: {
      ModalDefault
    },
  }
</script>

<style lang="scss" scoped>

</style>
