<template>
  <b-modal id="modal-login" ref="modal-login" no-stacking hide-footer centered no-close-on-esc no-close-on-backdrop hide-header-close @shown="onShown">
    <template v-slot:modal-header="">
      <img src="@/assets/images/fdm/logo-2.svg" alt="FDM logo">
    </template>

    <div class="error-wrapper" v-if="errorMsg">
      <b-alert show variant="danger">{{ $translations.getTranslation('login_modal_error_message') }}</b-alert>
    </div>

    <div class="button-wrapper">
      <button-primary @click.native="login" :spinner="isLoading">
        <span v-if="isLoading">
          {{ $translations.getTranslation('login_modal_button1_text1') }}
        </span>

        <span v-else>
          {{ $translations.getTranslation('login_modal_button1_text2') }}
        </span>
      </button-primary>
    </div>

  </b-modal>
</template>

<script>
  // Buttons
  import ButtonPrimary from '@/components/ui/button/Primary';

  // Models
  import VerifyApi from "@/models/verifyApi";
  import AuthApi from "@/models/authApi";
  import ProfileApi from "@/models/profileApi";

  // Configs
  import loginConfig from '@/configs/loginConfig';

  export default {
    name: "ModalLogin",

    props: {
      verifyToken: {
        default: {},
      }
    },

    components: {
      ButtonPrimary
    },

    data: function () {
      return {
        isLoading: false,
        errorMsg: null,
      }
    },

    methods: {
      onShown() {
        if (this.$user.isAuth()) {
          this.$root.$emit('bv::hide::modal', 'modal-login');
        }
      },

      hideErrorMsg() {
        this.errorMsg = false;
      },

      showErrorMsg() {
        this.errorMsg = true;
      },

      startLoading() {
        this.isLoading = true;
      },

      stopLoading() {
        this.isLoading = false;
      },

      login() {
        this.hideErrorMsg();
        this.authenticate();
      },

      authenticate() {
        if (!this.isLoading) {
          this.startLoading();

          let _this = this;
          new AuthApi({}, {}).post(function (response) {
            if (!response.status || response.status !== 200) {
              _this.showErrorMsg();
              _this.stopLoading();

              return;
            }

            // Set local storage
            localStorage.setItem(loginConfig.PRE_AUTH_TOKEN_KEY, response.data.preauth_token);

            // Redirect to login url
            window.location.replace(response.data.url);
          });
        }
      },

      verifyAuthToken(preAuthToken) {
        return new Promise((resolve, reject) => {
          let formData = {
            params: {
              preauth_token: preAuthToken,
              code: this.verifyToken.code,
              state: this.verifyToken.state,
              nonce: this.verifyToken.nonce,
            }
          };

          let _this = this;
          new VerifyApi(formData, {}).post(function (response) {
            if (!response.status || response.status !== 200) {
              return reject(response.statusText);
            }

            // Set token
            _this.$user.setTokenSessionStorage(response.data);

            return resolve('success');
          });
        });
      },

      getAuthProfile() {
        return new Promise((resolve, reject) => {
          // Get profile
          let _this = this;
          new ProfileApi({}, {}).get(function (response) {

            if (!response.status || response.status !== 200) {
              return reject(response.statusText);
            }

            // Set user info
            _this.$user.setName(response.data.first_name);
            _this.$user.setEverLinkedCard(response.data.everLinkedPaymentCard);
            _this.$user.setActiveLinkedPaymentCards(response.data.activeLinkedPaymentCards);
            _this.$user.setCardlessTransactions(response.data.cardlessTransactions);
            _this.$user.setHasResendableCardTransfers(response.data.hasResendableCardTransfers);
            _this.$user.setHasFailedBankTransfersOnActiveBankAccount(response.data.hasFailedBankTransfersOnActiveBankAccount);
            _this.$user.setCanSeeBankAccountsSection(response.data.canSeeBankAccountsSection);
            _this.$user.setActiveBankAccounts(response.data.activeBankAccounts);

            return resolve('success');
          });
        });
      },

      async verifyAuthHandler() {
        this.startLoading();
        // Get local storage
        let preAuthToken = localStorage.getItem(loginConfig.PRE_AUTH_TOKEN_KEY);

        // Delete local storage
        localStorage.removeItem(loginConfig.PRE_AUTH_TOKEN_KEY);

        // Verify auth token
        await this.verifyAuthToken(preAuthToken).catch((err) => {
          this.showErrorMsg();
          this.stopLoading();
          throw err;
        });

        // Get auth user profile
        await this.getAuthProfile().then(() => {
          // Authenticate
          this.$user.authenticate();

          // Get user info
          this.$user.getUser();

          this.$root.$emit('bv::hide::modal', 'modal-login');
          this.$root.$emit('ls::show::modal', 'modal-link-bank-account-notice');
          this.$root.$emit('ls::show::modal', 'modal-relink-bank-account-notice');
          this.$root.$emit('ls::show::modal', 'modal-cardless-transactions-notice');
        }).catch((err) => {
          this.showErrorMsg();
          this.stopLoading();
          throw err;
        });

        this.stopLoading();

        // Get auto login redirect val
        let autoLoginRedirect = localStorage.getItem(loginConfig.AUTO_LOGIN_WITH_REDIRECT_KEY);

        if ((this.$user.hasEverLinkedCard() || this.$user.hasCardlessTransactions()) &&
            autoLoginRedirect === 'ManageCards') {
          // Delete auto login local storage
          localStorage.removeItem(loginConfig.AUTO_LOGIN_WITH_REDIRECT_KEY);

          await this.$router.push({
            name: 'ManageCards'
          });
        }
      }
    },

    created() {
      this.$root.$on('ls::modal-login::auto-login', () => {
        this.login();
      });
    },

    watch: {
      'verifyToken': {
        handler() {
          this.verifyAuthHandler();
        },
        deep: true
      },

      '$root.userClass.authenticated': function (newVal) {
        if (!newVal) {
          this.$root.$emit('bv::show::modal', 'modal-login');
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  /deep/

  .modal-content {
    border-radius: 0;
    min-height: 250px;

    .modal-header {
      margin-bottom: 16px;

      img {
        width: 150px;
        display: block;
        margin: auto;
      }
    }

    .error-wrapper {
      width: 100%;
      margin-bottom: 16px;
      text-align: center;
    }

    .button-wrapper {
      width: 100%;
      max-width: 300px;
      margin: 32px auto 0 auto;

      button {
        margin-bottom: 16px;
      }
    }
  }
</style>
