import CreditCardsDefault from '@/models/creditCardsDefault';

export default class DankortCardNumber extends CreditCardsDefault{
    constructor(cardNumber) {
        super(cardNumber);
    }

    isScheme() {
        let dankortNumber = false;
        let cardNumber = this.cardNumber;

        let firstFour     = cardNumber.substring(0, 4);
        let cardNumberInt = parseInt(firstFour);

        switch (firstFour.length) {
            case 2:
                dankortNumber = (cardNumberInt === 50);
                break;
            case 3:
                dankortNumber = (cardNumberInt === 501);
                break;
            case 4:
                dankortNumber = (cardNumberInt === 5019);
        }

        return cardNumber.match((/^5019/)) || dankortNumber;
    }

    isStartOfScheme() {
        let cardNumber = this.cardNumber;
        return cardNumber.match(/^5/);
    }
}
