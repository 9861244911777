import Luhn from '@/models/luhnCheck';

export default class CreditCardsDefault {
    constructor(cardNumber) {
        this.cardNumber           = cardNumber;
        this.maxLength            = 16;
        this.maskWhiteSpacesCount = 3;
        this.mask                 = '#### #### #### ####';

        this.setMaskWhiteSpaceCount();
    }

    IsValidCardNumber() {
        // Only validate Luhn when card number length is equal to max length
        if (this.cardNumber && this.cardNumber.length === this.maxLength) {
            return new Luhn(this.cardNumber).isValidCardNumber();
        }

        return false;
    }

    setMaskWhiteSpaceCount() {
        this.maskWhiteSpacesCount = (this.mask.split(" ").length - 1);
    }

    getInputLimitations() {
        return {maxLength: (this.maxLength + this.maskWhiteSpacesCount).toString(), mask: this.mask};
    }

    getMask() {
        return this.mask;
    }

    getMaxLength() {
        return this.maxLength;
    }

    getMaxLengthWithWhiteSpaces() {
        return (this.maxLength + this.maskWhiteSpacesCount).toString();
    }
}
