<template>
    <div class="checkbox-default" v-if="isVisible">
        <div class="checkbox-default-inner">
            <div class="checkbox-inner-elm" :ref="checkboxRef" :id="checkboxId"
               v-on:click.prevent.stop="click()">

                <label class="checkmark-container" :for="checkboxId">
                    <slot name="label-content"> </slot>

                    <input type="checkbox" v-model="checked" v-on:keyup.enter="click()" tabindex="0">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: "CheckboxDefault",

        props: {
            checkboxRef: String,
            checkboxId: String,
            isVisible: Boolean,
        },

        data() {
            return {
                checked: false,
            }
        },

        methods: {
            click() {
                this.checked = !this.checked;
                this.emitChange();
            },

            reset() {
                this.checked = false;
                this.emitChange();
            },

            emitChange() {
                this.$emit('onChange', {
                    id: this.checkboxRef,
                    value: this.checked,
                });
            },
        },

        created: function() {
            this.$root.$on('ls::checkbox::reset', (id) => {
                if (id === this.checkboxId) {
                    this.reset();
                }
            });
        },

        watch: {
            'isVisible': function (newVal) {
                if (newVal) {
                    this.reset();
                }
            },
        }
    }
</script>


<style lang="scss" scoped>
  .checkbox-default {
      .checkbox-default-inner {
          width: 100%;
          display: block;
          float: left;

          // Used for accessibility
          .checkbox-inner-elm {
              color: black;
              display: block;
              float: left;

              &:hover, &:focus {
                  text-decoration: none;
              }

              a {
                  float: unset;
                  display: inline-block;
                  color: #007bff;

                  &:hover {
                      color: #0056b3;
                      text-decoration: underline;
                  }
              }
          }

          // The container
          .checkmark-container {
              display: block;
              position: relative;
              padding-left: 35px;
              margin: 10px 0;
              cursor: pointer;
              font-weight: normal;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
          }

          // Hide the browser's default checkbox
          .checkmark-container input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
          }

          // Create a custom checkbox
          .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 24px;
              width: 24px;
              background-color: white;
              border-radius: 4px;
              border: 1px solid #bfbcb5;
              transition: background-color .4s linear;
          }

          // On mouse-over, add a grey background color
          .checkmark-container:hover input ~ .checkmark {
              background-color: white;
              border-radius: 4px;
              border: 1px solid #bfbcb5;
          }

          // When the checkbox is checked, add a blue background
          .checkmark-container input:checked ~ .checkmark {
              background-color: #3ba9dc;
              border-radius: 4px;
              border: none;
              transition: background-color .4s linear;
          }

          // Create the checkmark/indicator (hidden when not checked)
          .checkmark:after {
              content: "";
              position: absolute;
              display: none;
          }

          // Show the checkmark when checked
          .checkmark-container input:checked ~ .checkmark:after {
              display: block;
          }

          // Style the checkmark/indicator
          .checkmark-container .checkmark:after {
              left: 9px;
              top: 4px;
              width: 6px;
              height: 13px;
              border: solid white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
          }

          .checkmark-container input {
              &:focus {
                  ~.checkmark {
                      outline-style: solid;
                      outline-color: black;
                      outline-width: 2px;
                  }
              }
          }
      }
  }
</style>
