import VisaCardNumber from '@/models/visaCardNumber';

export default class VisaDankortCardNumber extends VisaCardNumber {
    constructor(cardNumber) {
        super(cardNumber);
    }

    isScheme() {
        // Checks if card number starts with 4        
        return (this.cardNumber.match(/^4\d*$/) && this.cardNumber.match((/^4571/)));
    }
}