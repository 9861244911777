<template>
  <div class="invisible-captcha-component" v-if="captcha.isVisible">
    <vue-captcha
       ref="invisibleCaptcha"
       badge="badge"
       size="invisible"
       :sitekey="captcha.siteKey"
       @verify="onVerifyEvent"
       @error="onErrorEvent"
       @expired="onExpiredEvent()">
    </vue-captcha>
  </div>
</template>

<script>
  import VueCaptcha from 'vue-recaptcha';

  // Configs
  import captchaConfig from '@/configs/captchaConfig';

  export default {
    name: "InvisibleCaptcha",

    props: {
      triggerCaptcha: Boolean,
    },

    components: { VueCaptcha },

    data: function() {
      return {
        captcha: {
          isVisible: false,
          siteKey: captchaConfig.SITE_KEY,
        }
      }
    },

    beforeMount() {
      this.addCaptchaApiScript();
    },

    mounted() {
      this.$nextTick(function () {
        this.activateCaptcha();

        let _this = this;
        this.$parent.$on('triggerCaptcha', function () {
          _this.execute();
        });
      })
    },

    methods: {
      addCaptchaApiScript() {
        let script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit";
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
      },

      onVerifyEvent(token) {
        this.$emit('OnVerify', token);
      },

      onExpiredEvent() {
        this.$emit('OnExpired');
        this.resetCaptcha();
      },

      onErrorEvent() {
        this.$emit('onError');
        this.resetCaptcha();
      },

      execute() {
        this.resetCaptcha();
        this.$refs['invisibleCaptcha'].execute();
        this.detectChallengeChange();
      },

      resetCaptcha() {
        this.$refs['invisibleCaptcha'].reset();
      },

      activateCaptcha() {
        let _this = this;

        setTimeout( function () {
          _this.captcha['isVisible'] = true;
        }, 800);
      },

      challengeOnClose() {
        this.$emit('OnCancel');
      },

      detectChallengeChange() {
        let captchaChallengeChange = new Promise(function(resolve) {
          const targetElement = document.body;

          const observerConfig = {
            childList: true,
          };

          function DOMChangeCallbackFunction(mutationRecords) {
            mutationRecords.forEach((mutationRecord) => {
              if (mutationRecord.addedNodes.length) {
                const reCaptchaParentContainer = mutationRecord.addedNodes[0];
                const reCaptchaIframe          = reCaptchaParentContainer.querySelectorAll('iframe[title*="reCAPTCHA-udfordring"]');

                if (reCaptchaIframe.length) {
                  reCaptchaObserver.disconnect();
                  resolve(reCaptchaParentContainer);
                }
              }
            });
          }

          const reCaptchaObserver = new MutationObserver(DOMChangeCallbackFunction);
          reCaptchaObserver.observe(targetElement, observerConfig);
        });

        let _this = this;
        captchaChallengeChange.then( function (reCaptchaParentContainer) {
          const reCaptchaChallengeClosureObserver = new MutationObserver(function () {
            if (reCaptchaParentContainer.style.visibility === 'hidden' && !window.grecaptcha.getResponse()) {
              _this.challengeOnClose();
              reCaptchaChallengeClosureObserver.disconnect();
            }
          });

          reCaptchaChallengeClosureObserver.observe(reCaptchaParentContainer, {
            attributes: true,
            attributeFilter: ['style']
          });
        });
      },

    },
  }
</script>
