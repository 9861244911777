import Axios from "@/models/axios";
import AxiosConfig from '@/configs/axiosConfig';

class LinkCardApi extends Axios {
  constructor(formData, config) {
    super();
    this.formData = formData;
    this.config   = config;
    this.baseUrl  =  AxiosConfig.BASE_CDE_API_URL;
  }

  path() {
    return 'cards';
  }
}

export default LinkCardApi;