import CreditCardsDefault from '@/models/creditCardsDefault';

export default class MastercardCardNumber extends CreditCardsDefault {
    constructor(cardNumber) {
        super(cardNumber);
    }

    isScheme() {
        let betweenBinRange = false;
        let cardNumber = this.cardNumber;

        let firstFour     = cardNumber.substring(0, 4);
        let cardNumberInt = parseInt(firstFour);

        switch (firstFour.length) {
            case 1:
                betweenBinRange = (cardNumberInt === 2);
                break;
            case 2:
                betweenBinRange = (cardNumberInt >= 22 && cardNumberInt <= 27);
                break;
            case 3:
                betweenBinRange = (cardNumberInt >= 222 && cardNumberInt <= 272);
                break;
            case 4:
                betweenBinRange = (cardNumberInt >= 2221 && cardNumberInt <= 2720);
        }

        return cardNumber.match(/^5([1-58]\d*)?$/) || betweenBinRange;
    }

    isStartOfScheme() {
        let cardNumber = this.cardNumber;
        return cardNumber.match(/^5/) || cardNumber.match(/^2/);
    }
}