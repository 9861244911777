import BlockedBinsConfig from '@/configs/blockedBinsConfig';

export default class BlockedBinNumbers {
    constructor(cardNumber) {
        this.cardNumber  = cardNumber;
        this.blockedBins = [];
        this.dankortOnlyblockedBins = BlockedBinsConfig.DANKORT_BINS;
        this.lopiBlockedBins = BlockedBinsConfig.LOPI_BINS;
    }

    isBlockedBins() {
        let cardNumber = this.cardNumber;

        if (cardNumber && cardNumber.length >= 6) {
            let firstSixBins = parseInt(cardNumber.substring(0, 6));
            return this.blockedBins.includes(firstSixBins);
        }

        return false;
    }

    isDankortOnlyBlockedBins() {
        let cardNumber = this.cardNumber;

        if (cardNumber && cardNumber.length >= 4) {
            let firstFourBins = parseInt(cardNumber.substring(0, 4));
            return this.dankortOnlyblockedBins.includes(firstFourBins);
        }

        return false;
    }
    
    isLopiBlockedBins() {
        let cardNumber = this.cardNumber;

        if (cardNumber && cardNumber.length >= 6) {
            let firstSixBins = parseInt(cardNumber.substring(0, 6));
            return this.lopiBlockedBins.includes(firstSixBins);
        }

        return false;
    }
}
